import { RouteConfig } from 'vue-router'

const routes: RouteConfig[] = [
  {
    path: '/participant',
    component: () => import('@/views/participant/Home.vue')
  },
  {
    path: '/participant/content/:id',
    component: () => import('@/views/participant/ViewContent.vue')
  }
]

export default routes;
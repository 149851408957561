import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { AxiosInstance } from "axios";
import API from '@/plugins/axios';
import "./plugins/TipTap";
Vue.config.productionTip = false
import VueApexCharts from 'vue-apexcharts'
import i18n from '@/plugins/i18n';
import "@lottiefiles/lottie-player";
import '@/plugins/i18n';


declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosInstance;
  }
}

Vue.prototype.$http = API;
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import { auth } from '@/firebase';

auth.onAuthStateChanged(async () => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})

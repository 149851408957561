import Vue from "vue";
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)


import messages from '../locales'


export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || localStorage.getItem('lang') || window.navigator.language,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "nl",
  messages: messages,
  silentTranslationWarn: true,
});

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyBsycAC6zaQUm41k_nTP1CqSB7ugGxKBY4",
  authDomain: "itinera-342308.firebaseapp.com",
  projectId: "itinera-342308",
  storageBucket: "itinera-342308.appspot.com",
  messagingSenderId: "387314086305",
  appId: "1:387314086305:web:36e927d516524af105dbe0",
  measurementId: "G-WTNSQXGMWD"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
const firestore = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

functions.region = 'europe-west1';

export { firestore, auth, storage, functions };
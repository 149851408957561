import { GetterTree } from "vuex";
import { RootState } from "../types";
import { UserState } from "./types";

export enum USER_GETTERS {
  IS_AUTHENTICATED = "IS_AUTHENTICATED",
  USER_TYPE = "USER_TYPE",
  AUTH_LOADING = "AUTH_LOADING",
  ACTING_USER = "ACTING_USER",
  IS_IMPERSONATING = 'IS_IMPERSONATING'
}
export const getters: GetterTree<UserState, RootState> = {
  [USER_GETTERS.IS_AUTHENTICATED]: (state) => {
    return state.authenticated;
  },
  [USER_GETTERS.USER_TYPE]: (state) => {
    return state.profile.type
  },
  [USER_GETTERS.AUTH_LOADING]: (state) => {
    return state.authenticationLoading;
  },
  [USER_GETTERS.ACTING_USER]: (state) => {
    return state.profile
  },
  [USER_GETTERS.IS_IMPERSONATING]: (state) => {
    return state.impersonating
  }
}
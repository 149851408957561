import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/admin/Home.vue'

Vue.use(VueRouter)

import store from '@/store';
import { USER_GETTERS } from '@/store/user/getters';

import adminRoutes from '@/router/modules/admin'
import coachRoutes from '@/router/modules/coach';
import participantRoutes from '@/router/modules/participant'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  ...adminRoutes,
  ...coachRoutes,
  ...participantRoutes,
  {
    path: '/login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/confirm-account',
    redirect: '/login'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const whitelist = ['/login', '/confirm-account', '/reset-password']

router.beforeEach(async (to, from, next) => {
  if (store.getters[USER_GETTERS.AUTH_LOADING]) {
    return setTimeout(() => {
      next(to.fullPath)
    }, 200)
  }


  if (store.getters[USER_GETTERS.IS_AUTHENTICATED]){
    const userType = store.getters[USER_GETTERS.USER_TYPE]
    if (to.path.startsWith(`/${userType.toLowerCase()}`)) {
      return next();
    } else {
      return next(`/${userType.toLowerCase()}`);
    }
  }


  if (store.getters[USER_GETTERS.IS_AUTHENTICATED]) {
    return setTimeout(() => {
      next(to.fullPath)
    }, 200)
  } else {
    const userType = store.getters[USER_GETTERS.USER_TYPE]
    if (!whitelist.includes(to.path) && !store.getters[USER_GETTERS.IS_AUTHENTICATED]) {
      return next('/login');
    } else {
      if (to.path.startsWith(`/${userType.toLowerCase()}`)) {
        return next();
      } else {
        return next(`/${userType.toLowerCase()}`);
      }
    }
  }
});

export default router


import { Module } from "vuex";
import { RootState } from "../types";
import { UserState } from "./types";
import { getters } from '@/store/user/getters'
import { mutations } from "@/store/user/mutations"
import { actions } from "./actions";
import { Timestamp } from "firebase/firestore";

const state: UserState = {
  authenticated: false,
  authenticationLoading: true,
  impersonating: false,
  impersonatingUser: null,
  profile: {
    id: "",
    firstname: "",
    lastname: "",
    email: '',
    type: '',
    phone: '',
    lastActive: Timestamp.now(),
    language: 'nl-be',
    pending: false,
  }
};

export const user: Module<UserState, RootState> = {
  state,
  actions,
  getters,
  mutations
}
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { USER_MUTATIONS } from "./mutations";
import { UserState } from "./types";

import { firestore, auth } from '@/firebase'
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { signOut } from "firebase/auth";

export enum USER_ACTIONS {
  AUTHENTICATE_USER = "AUTHENTICATE_USER",
  SIGNOUT = "SIGNOUT"
}

export const actions: ActionTree<UserState, RootState> = {
  async [USER_ACTIONS.AUTHENTICATE_USER]({ commit }) {
    const authUser = auth.currentUser;

    if (!authUser) {
      commit(USER_MUTATIONS.SET_USER, null);
    } else {
      const userRef = doc(firestore, "users", authUser.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        await updateDoc(userRef, { lastLogin: new Date() })
        commit(USER_MUTATIONS.SET_USER, {
          id: userDoc.id,
          ...userDoc.data(),
        });
      }
    }
  },
  async [USER_ACTIONS.SIGNOUT]({ commit }) {
    await signOut(auth);
    commit(USER_MUTATIONS.SET_USER, null);
  }
}
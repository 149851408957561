
import { User } from "@/store/user/types";
import { Component, Vue } from "vue-property-decorator";
import { ROOT_ACTIONS } from "@/store/actions";
import { USER_GETTERS } from "@/store/user/getters";
import { Timestamp, collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { firestore } from "@/firebase";
import { startOfDay, endOfDay } from 'date-fns';

@Component({
  components: {}
})
export default class Home extends Vue {
  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  users: User[] = [];

  chartData = {
    actualActivitiesPerDay: {
      data: [] as number[],
      labels: [] as string[]
    }
  }

  activeUserToday = 0;
  actualActivitiesHours = 0;


  loading = {
    profiles: true,
    actualActivitiesPerDay: true
  };

  mounted() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Home', target: '/' }])
    this.getUsers();
    this.countActiveUsersToday();
    this.countActualHours();
  }

  async countActiveUsersToday() {
    const today = new Date();
    const startOfToday = Timestamp.fromDate(startOfDay(today));
    const endOfToday = Timestamp.fromDate(endOfDay(today));

    const count = await getDocs(query(collection(firestore, 'users'), where('lastLogin', '>=', startOfToday), where('lastLogin', '<=', endOfToday)));

    this.activeUserToday = count.size;
  }

  async countActualHours() {
    const data = await getDoc(doc(firestore, 'stats', 'coaching'))
    if (data.exists()) {
      this.actualActivitiesHours = data.data()!.totalHours
    } else {
      this.actualActivitiesHours = 0
    }
  }

  async getUsers() {
    const users = await getDocs(collection(firestore, 'users'));
    this.users = users.docs.map((e) => ({ id: e.id, ...e.data() } as User));
  }
}

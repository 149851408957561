import { RouteConfig } from 'vue-router'

const routes: RouteConfig[] = [
  {
    path: '/admin',
    component: () => import('@/views/admin/Home.vue')
  },
  {
    path: '/admin/users',
    component: () => import('@/views/admin/Users/Index.vue')
  },
  {
    path: '/admin/users/:userId',
    component: () => import('@/views/admin/Users/Overview.vue')
  },
  {
    path: '/admin/interim',
    component: () => import('@/views/admin/Users/Index.vue')
  },
  {
    path: '/admin/presence/programs',
    component: () => import('@/views/shared/Programs/Index.vue')
  },
  {
    path: '/admin/presence/programs/:id',
    component: () => import('@/views/shared/Programs/Program.vue')
  },
  {
    path: '/admin/data',
    component: () => import('@/views/admin/Data/Index.vue')
  },
  {
    path: '/admin/presence',
    component: () => import('@/views/admin/Presence/Index.vue'),
    redirect: '/admin/presence/overview',
    children: [
      {
        path: 'program/:id',
        component: () => import('@/views/shared/Programs/Program.vue')
      },
      {
        path: 'overview',
        component: () => import('@/views/admin/Presence/Overview.vue')
      },
      {
        path: 'templates',
        component: () => import('@/views/admin/Presence/Templates.vue')
      },
      {
        path: 'templates/new',
        component: () => import('@/views/admin/Presence/NewTemplate.vue')
      },
      {
        path: 'templates/:id',
        component: () => import('@/views/admin/Presence/TemplateOverview.vue'),
      },
      {
        path: 'new-program',
        component: () => import('@/views/admin/Presence/NewProgram.vue')
      },
      {
        path: 'new-voluntary-program',
        component: () => import('@/views/admin/Presence/NewVoluntaryProgram.vue')
      }
    ]
  },
  {
    path: '/admin/webinars',
    component: () => import('@/views/shared/Webinar/Index.vue'),
  },
  {
    path: '/admin/webinars/:id',
    component: () => import('@/views/shared/Webinar/Details.vue'),
  },
  {
    path: '/admin/library',
    component: () => import('@/views/admin/Library/Index.vue'),
    redirect: '/admin/library/content',
    children: [
      {
        path: 'content',
        component: () => import('@/views/admin/Library/Content.vue')
      },
      {
        path: 'archive',
        component: () => import('@/views/admin/Library/Archived.vue')
      }
    ]
  },
  {
    path: '/admin/library/:id',
    component: () => import('@/views/admin/Library/ContentItem.vue')
  },
  {
    path: '/admin/content-assignment',
    component: () => import('@/views/shared/ContentAssignment/Index.vue')
  },
]

export default routes;
import { MutationTree } from "vuex";
import { User, UserState } from "./types";
import i18n from "@/plugins/i18n";

export enum USER_MUTATIONS {
  SET_USER = "SET_USER",
}

export const mutations: MutationTree<UserState> = {
  [USER_MUTATIONS.SET_USER](state, payload: any) {
    if (payload) {
      state.profile = payload;
      state.authenticated = true;

      if (payload.impersonating) {
        state.impersonating = true;
        state.impersonatingUser = payload.impersonating
      }
  
      i18n.locale = state.impersonating ? payload.impersonating.language : payload.language

    } else {
      state.authenticated = false;
    }
    state.authenticationLoading = false;
  },
}
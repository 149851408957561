import { RouteConfig } from 'vue-router'

const routes: RouteConfig[] = [
  {
    path: '/coach',
    component: () => import('@/views/coach/Home.vue')
  },
  {
    path: '/coach/program/:id',
    component: () => import('@/views/shared/Programs/Program.vue')
  },
  {
    path: '/coach/webinars',
    component: () => import('@/views/shared/Webinar/Index.vue'),
  },
  {
    path: '/coach/webinars/:id',
    component: () => import('@/views/shared/Webinar/Details.vue'),
  },
  {
    path: '/coach/content-assignment',
    component: () => import('@/views/shared/ContentAssignment/Index.vue')
  }
]

export default routes;